






































import { Component, Vue, Emit, Prop, Watch } from 'vue-property-decorator';
import { dateFromString, dateStringToTimeRange, timestampToDateString } from '@/shared/formatters';

const now = new Date();
@Component({})
export default class TimeRangeSelect extends Vue {
  dateFrom = '';

  dateTo = '';

  @Prop() value!: string;

  @Emit('input')
  emitTimeRange() {
    return this.timeRange;
  }

  @Watch('value')
  valueChanged(val) {
    console.log('TimeRange', val);
    if (val !== this.value) {
      this.updateInput();
    }
  }

  updateInput() {
    const tr = this.value.split('-');
    if (tr[0]) {
      this.dateFrom = timestampToDateString(dateFromString(tr[0], 'yyyyMMdd'));
    }
    if (tr.length > 1 && tr[1]) {
      this.dateTo = timestampToDateString(dateFromString(tr[1], 'yyyyMMdd'));
    }
  }

  created() {
    if (!this.value) {
      this.dateFrom = timestampToDateString(new Date(now.getFullYear(), now.getMonth() - 1, 1));
    } else {
      this.updateInput();
    }
    this.emitTimeRange();
  }

  updated() {
    this.emitTimeRange();
  }

  get timeRange() {
    if (this.dateFrom !== '' || this.dateTo !== '') {
      return `${dateStringToTimeRange(this.dateFrom)}-${dateStringToTimeRange(this.dateTo)}`;
    }
    return '';
  }
}
