














import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const ftbot = namespace('ftbot');

@Component({})
export default class StrategySelect extends Vue {
  @Prop() value!: string;

  @Prop({ default: false, required: false }) showDetails!: boolean;

  @ftbot.Action getStrategyList;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  @ftbot.Action getStrategy!: (strategy: string) => void;

  @ftbot.State strategyList!: string[];

  @ftbot.State strategy;

  @Emit('input')
  emitStrategy(strategy: string) {
    this.getStrategy(strategy);
    return strategy;
  }

  get strategyCode(): string {
    return this.strategy?.code;
  }

  get locStrategy() {
    return this.value;
  }

  set locStrategy(val) {
    this.emitStrategy(val);
  }

  strategyChanged(newVal) {
    this.value = newVal;
  }

  mounted() {
    if (this.strategyList.length === 0) {
      this.getStrategyList();
    }
  }
}
