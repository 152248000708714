



































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import CandleChartContainer from '@/components/charts/CandleChartContainer.vue';
import TimeRangeSelect from '@/components/ftbot/TimeRangeSelect.vue';
import TimeframeSelect from '@/components/ftbot/TimeframeSelect.vue';
import StrategySelect from '@/components/ftbot/StrategySelect.vue';
import { AvailablePairPayload, AvailablePairResult, WhitelistResponse } from '@/types';
import { BotStoreGetters } from '@/store/modules/ftbot';

const ftbot = namespace('ftbot');

@Component({
  components: { CandleChartContainer, StrategySelect, TimeRangeSelect, TimeframeSelect },
})
export default class Graphs extends Vue {
  historicView = false;

  strategy = '';

  timerange = '';

  selectedTimeframe = '';

  @ftbot.State pairlist;

  @ftbot.State whitelist!: string[];

  @ftbot.State trades;

  @ftbot.Getter [BotStoreGetters.timeframe]!: string;

  @ftbot.Getter [BotStoreGetters.isWebserverMode]!: boolean;

  @ftbot.Action public getWhitelist!: () => Promise<WhitelistResponse>;

  @ftbot.Action public getAvailablePairs!: (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    payload: AvailablePairPayload,
  ) => Promise<AvailablePairResult>;

  mounted() {
    this.historicView = this.isWebserverMode;
    if (!this.whitelist || this.whitelist.length === 0) {
      this.getWhitelist();
    }
    if (this.historicView) {
      // this.refresh();
      this.getAvailablePairs({ timeframe: this.timeframe }).then((val) => {
        // console.log(val);
      });
    }
  }
}
