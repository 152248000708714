






import { Component, Vue } from 'vue-property-decorator';
import LogViewer from '@/components/ftbot/LogViewer.vue';

@Component({
  components: { LogViewer },
})
export default class LogView extends Vue {}
